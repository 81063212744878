.container {
  width: 40vw;
  background-color: #ecececa8;
  color: black;
  height: 30vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 5px 5px 2.5px #dfdfdf7e;
}

.product_info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.image_container {
  width: 30%;
  display: flex;
  align-items: center;
  object-fit: contain;
}

.img {
  border-radius: 10px;
}

.info_container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info_text {
  margin-bottom: 1vh;
  font-size: 15px;
  font-family: "Gilroy-SemiBold";
}

.btn_container {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.current_auction__btn {
  width: 100%;
  height: 7vh;
  border-radius: 10px;
  background-color: white;
  border: 2px solid black;
  color: black;
  font-family: "Gilroy-SemiBold";
  cursor: pointer;
  transition: 300ms;
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0.02rem;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -2;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(black, 15%);
    transition: all 0.3s;
    z-index: -1;
  }

  &:hover {
    color: #fff;

    &:before {
      width: 100%;
    }
  }
}

.btn {
  @extend .current_auction__btn;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  width: 80%;
}

.user_info {
  width: 100%;
  margin-top: 1vh;
}

.user_info_btn {
  text-align: center;
  cursor: pointer;
}

.user_info_container {
  background-color: #ececec;
  padding: 1vh 2vw;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.user_info_text {
  margin-bottom: 1vh;
  font-size: 14px;
  color: #333;
}

@media (max-width: 870px) {
  .product_info {
    flex-direction: column;
    align-items: center;
  }

  .image_container {
    width: 100%;
    margin-bottom: 2vh;
  }

  .info_container {
    width: 100%;
    text-align: center;
  }

  .btn_container {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }

  .btn {
    margin-bottom: 0;
    width: auto;
  }
}
