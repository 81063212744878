.container {
  width: 200px;
  height: 200px;
  border: 2px solid #cccccc70;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.uploadInput {
  display: none;
}

.uploadIcon {
  font-size: 48px;
  color: #aaa;
}

.previewContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.previewVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  pointer-events: none; /* Video tıklanabilirliği kapatıldı */
}

.removeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
