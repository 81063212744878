.root {
  width: 800px;
  height: auto;
  position: relative;
}

.rootButton {
  height: 40px;
  border-radius: 5px;
  width: 100%;
  border: 2px solid rgba(128, 128, 128, 0.347);
  padding: 6px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 500ms;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px;
}

.searchInput {
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid rgba(171, 171, 171, 0.144);
  &:focus {
    outline: none;
  }
}

.searchInput::placeholder {
  color: gray;
}

.optionItem {
  height: 40px;
  width: 100%;
  padding: 6px 8px;
  border: 0.5px solid rgba(164, 162, 162, 0.144);
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
  }
  animation: fadeIn 0.3s ease-in-out;
  display: flex;
  align-items: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
