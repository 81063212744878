@mixin auction_layout() {
  width: 80vw;
  background-color: #ecececa8;
  color: black;
  height: 30vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 1vh;
  padding-left: 2vw;
  padding-right: 2vw;
  box-shadow: 5px 5px 2.5px #dfdfdf7e;
}

.current_auction {
  @include auction_layout;
}

.image_container {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding: 15px;
}

.img {
  height: 100%;
  width: auto;
  max-width: 100%;
  border-radius: 10px;
  transition: 800ms;
  cursor: pointer;
}

.auction_info_container {
  width: 32.5%;
  height: 100%;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5vh;
}

.auction_name {
  font-size: 30px;
  font-family: "Gilroy-SemiBold";
}

.info_text {
  margin-bottom: 1vh;
  font-size: 15px;
  min-width: 7vw;
  margin-right: 2vw;
}

.btn_container {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.current_auction__btn {
  width: 100%;
  height: 7vh;
  border-radius: 10px;
  background-color: white;
  border: 2px solid black;
  color: black;
  font-family: "Gilroy-SemiBold";
  cursor: pointer;
  transition: 300ms;
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0.02rem;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -2;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(black, 15%);
    transition: all 0.3s;
    z-index: -1;
  }

  &:hover {
    color: #fff;

    &:before {
      width: 100%;
    }
  }
}

@media (max-width: 870px) {
  .current_auction {
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
  }

  .image_container {
    width: 100%;
    height: auto;
  }

  .img {
    margin: auto;
  }

  .auction_info_container {
    width: 100%;
    padding: 2vh;
    text-align: center;
  }

  .btn_container {
    width: 80%;
    height: 50px;
    margin-bottom: 2vh;
  }

  .current_auction__btn {
    letter-spacing: 1.5px;
    font-size: 17px;
  }
}
