.artist_container {
  // background-color: red;
  //   width: 80vw;
  //   margin: auto;
  display: grid;
  grid-template-columns: repeat(5, calc(70.5 / 5 * 1vw));
  gap: 0.25vw;
  justify-content: center;
  align-items: center;
}
