.container {
  width: 80%;
  margin: 0 auto;
}

.buttonGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.gridButton {
  background-color: #a52834;
  color: white;
  border: none;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  aspect-ratio: 3/2;
  transition: background-color 0.3s;
}

.gridButton:hover {
  background-color: #0056b3;
}
