.root {
  border: 1px solid rgba(128, 128, 128, 0.301);
  border-radius: 5px;
  //   background-color: red;
  font-size: 19px;
  align-self: center;
  margin: auto;
  min-width: 800px;
  font-size: 20px;
  box-sizing: border-box;
  padding: 4px;
}

.dropdown {
  min-width: 800px;
  border: 4px solid green;
}

.image_grid_container {
  // background-color: red;
  //   width: 80vw;
  //   margin: auto;
  margin-bottom: 2vh;
  display: grid;
  grid-template-columns: repeat(5, calc(70.5 / 5 * 1vw));
  gap: 0.25vw;
  justify-content: center;
  align-items: center;
}
