.blogItem {
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
}

.blogLink {
  text-decoration: none;
  color: inherit;
  display: block;
}

.blogImage {
  position: relative;
  overflow: hidden;
  aspect-ratio: 5/3;

  img {
    width: 100%;
    display: block;
    transition: transform 0.3s;
    object-fit: contain;
  }

  &:hover img {
    transform: scale(1.1);
  }

  .blogTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    color: black;
    font-weight: bold;
    background-color: white;
    padding: 5px 10px;
    font-size: 1.2em;
  }
}
.blogMeta {
  padding: 10px 20px;

  .redLine {
    height: 2px;
    background-color: red;
    margin: 10px 0;
  }

  .blogDateAuthor {
    font-size: 0.9em;
    color: #555;
    margin: 0;
    letter-spacing: 0.5px;

  }
}


.blogContent {
  padding: 0 20px 20px 20px;

  p {
    margin: 0;
    max-height: 5em; /* Yaklaşık 5 satır sınırı */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Satır sınırı */
    -webkit-box-orient: vertical;

    .readMore {
      color: red;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
