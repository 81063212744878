.finished_products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  gap: 20px;
  padding: 10px;

  p {
    grid-column: span 2;
    text-align: center;
    font-size: 18px;
    color: #666;
  }
}

@media (max-width: 870px) {
  .finished_products {
    grid-template-columns: 1fr;
  }
}
