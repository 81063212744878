.product_card {
  height: 60vh;
  width: 20vw;
  background-color: #ecececa8;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1%;
  border-radius: 10px;
  box-shadow: 5px 5px 1px #dedede7e;
  position: relative;
}

.product_card__img {
  height: 40%;
  width: auto;
  margin-bottom: 1vh;
  max-width: 100%;
  cursor: pointer;
}

.text_bold {
  font-family: "Gilroy-SemiBold";
  font-size: 15px;
  margin-bottom: 0.2vh;
}

.text_normal {
  margin-top: 0.8vh;
  font-size: 15px;
  text-align: center;
  margin-bottom: 2vh;
  width: 80%;
  height: 3vh;
}

.text_without_margin {
  font-size: 15px;
  text-align: center;
  width: 80%;
  height: auto;
}

.product_card__btn_container {
  width: 80%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1vh;
}

.banner {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: white;
  border: 2px solid black;
  color: black;
  // border: none;
  font-family: "Gilroy-SemiBold";
  cursor: pointer;
  transition: 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 1rem;
  letter-spacing: 0.02rem;
  transition: all 0.3s;
}

.bannerText {
  box-sizing: border-box;
  width: 80%;
  height: 5vh;
  background-color: white;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: auto;
  margin-top: 1vh;
  margin-bottom: 2vh;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.product_card__btn_container__btn {
  width: 47%;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  // border: 2px solid black;
  color: black;
  // border: none;
  font-family: "Gilroy-SemiBold";
  cursor: pointer;
  transition: 300ms;
  // &:hover {
  //   background-color: #950101;
  //   color: white;
  // }
  box-shadow: inset gray 0px 0px 60px -12px;

  display: inline-block;
  color: black;
  font-size: 1rem;
  letter-spacing: 0.02rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: black;
    background-color: white;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(black, 15%);
    color: white;
    transition: all 0.3s;
    z-index: -1;
    box-shadow: inset gray 0px 0px 4px -2px;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
}

.bidCount {
  position: absolute;
  letter-spacing: 2px;
  bottom: 5px;
  width: 80%;
  text-align: center;
}

