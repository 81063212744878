.auction_details {
  box-sizing: border-box;
  // background-color: rgb(174, 12, 12);
  color: black;
  height: auto;
  max-width: 100vw;
}

.auction_details__header {
  margin-top: 5vh;
  width: 100vw;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.auction_details__header__text {
  font-size: 18px;
  margin-bottom: 0.4vh;
}

.bold {
  letter-spacing: 1px;
  font-family: "Gilroy-SemiBold";
}

.form {
  margin-top: 0.4vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.4vh;
}

::placeholder {
  color: black;
}

.form__text_inp {
  width: 10vw;
  box-sizing: border-box;
  height: 5vh;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #ededed9d;
  letter-spacing: 0.5px;
  margin-left: 1vw;
  padding: 10px;
  padding-left: 10px;
  transition: 300ms;
  &:focus {
    background-color: white;
    box-shadow: 0 0 2px 2px gray;
  }
}

.toggle_btn {
  cursor: pointer;
  width: 10vw;
  height: 5vh;
  border-radius: 10px;
  outline: none;
  border: 1px solid black;
  background-color: #ededed9d;
  letter-spacing: 0.5px;
  margin-left: 1vw;
  padding: 10px;
  padding-left: 10px;
  font-family: "Gilroy-SemiBold";
  box-sizing: border-box;
  transition: 400ms ease-out;
  &:hover {
    box-shadow: 0 0 1px 1px gray;
  }
}

.auction_product_container {
  width: 76vw;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, calc(74.5 / 4 * 1vw));
  gap: 2vw;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
  margin-bottom: 5vh;
}

@media (max-width: 1100px) {
  .auction_product_container {
    width: 100vw;
    grid-template-columns: repeat(2, calc(78.5 / 2 * 1vw));
  }
  .form {
    width: 90vw;
  }
}

@media (max-width: 750px) {
  .auction_product_container {
    box-sizing: border-box;
    width: 98vw;
    margin: auto;
    grid-template-columns: repeat(1, 80vw);
    gap: 3vh;
    justify-content: center;
    align-items: center;
  }
  .form {
    display: none;
  }
}
