.markdown-preview {
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
}

.markdown-preview span {
  color: inherit; /* Ensure the color from inline styles is applied */
}

/* Fullscreen butonunu gizlemek için CSS */
.rc-md-editor .rc-md-navigation .button-wrap .button:last-child {
  display: none !important;
}